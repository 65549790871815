import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,Container,Row,Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';

import logo from './logo.svg';
import ARM_Logo from "./images/ARM_Logo.png"
import DOE_Logo from "./images/doe.png"
import dashboard_img from "./images/dashboard.png"
import faq_tutorials from "./images/faq_tutorials.png"
import team_img from "./images/ARMDQO_1.jpg"


import './App.css';
import './index.css';
import './navbar.css';

export default class App extends Component {
  render() {
    return (
      <div>
        <HomePage/>
      </div>
    );
  }
}


export class NavBar extends Component {
  render() {
    let url = window.location.href;

    let dqExplorerLink       = ""
    let dqExplorerLegacyLink = ""
    let dqZoomLink           = ""
    let dqPlotbrowserLink    = ""

    // Is DEV System
    if(url.indexOf("dev") >= 0){

      let regex = new RegExp('/[~](.+?)/');
      let username = url.match(regex)[1]

      dqExplorerLink       = "https://dq.arm.gov/dq-explorer/"
      dqPlotbrowserLink    = "https://dev.arm.gov/~" + username + "/dq/dq-plotbrowser/"
      dqZoomLink           = "https://dev.arm.gov/~" + username + "/dq/dq-zoom/"
    }
    //Is Production System
    else{
      dqExplorerLink       = "https://dq-explorer-ui.svcs.arm.gov/"
      dqPlotbrowserLink    = "https://dq-plotbrowser-ui.svcs.arm.gov/"
      dqZoomLink           = "https://dq-zoom-ui.svcs.arm.gov/"
    }

    return (
      <div>
        <div className="header">
          <div className="center-div">
            <img id="arm-logo" src={ARM_Logo}/>
            <p className="header-text"><Link to="/">Data Quality Office</Link></p>
          </div>
        </div>
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
            
            <Container >
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
{/*                        <Nav.Link as={Link} className="dropdown" to="/about">About the DQO</Nav.Link>*/}
                        <NavDropdown title="Tools">
                            <NavDropdown.Item href={dqExplorerLink} target="_blank">DQ-Explorer</NavDropdown.Item>
                            <NavDropdown.Item href={dqPlotbrowserLink} target="_blank">DQ-Plotbrowser</NavDropdown.Item>
                            <NavDropdown.Item href={dqZoomLink} target="_blank">DQ-Zoom</NavDropdown.Item>
                            <NavDropdown.Item href="https://dq-dashboard-ui.svcs.arm.gov/" target="_blank">DQ-Dashboard</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Reports">
                            <NavDropdown.Item href="https://dqpr-ui.svcs.arm.gov/">DQPR</NavDropdown.Item>
                            <NavDropdown.Item href="https://adc.arm.gov/DQRSubmissionTool/">DQR</NavDropdown.Item>
                            <NavDropdown.Item href="https://adc.arm.gov/dqa/">DQA</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Documentation">
                            <LinkContainer to="/About">
                                <NavDropdown.Item>About the DQO</NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <LinkContainer to="/FAQ">
                                <NavDropdown.Item>FAQ</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/tutorials">
                                <NavDropdown.Item>Tutorials</NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="https://dq.wiki.arm.gov">DQO Wiki</NavDropdown.Item>
                            <NavDropdown.Item href="https://code.arm.gov/docs/QC-flag-examples/wikis/home">Embedded QC Flag</NavDropdown.Item>
                            <NavDropdown.Item href="https://code.arm.gov/docs/dqrws-examples/wikis/home">DQR Web Service</NavDropdown.Item>
                        </NavDropdown>

                    </Nav>
                <Navbar.Brand href="#home"><img id="doe-logo" src={DOE_Logo}/></Navbar.Brand>
                </Navbar.Collapse>
                
            </Container>
        </Navbar>
      </div>
    )
  }
}

class NewsFeed extends Component {
  render() {
    return(
      <div>
        <div className="news-feed">
          <h3 className="news-feed-header">Recent Highlights:</h3>
           <Row className="news-feed-entry">
            <Col lg={6} md={12}>
              <div className="news-feed-entry-image-container">
                <div className="news-feed-entry-image">
                    <img className="news-feed-entry-image" src={faq_tutorials}/>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="news-feed-entry-text-container">
                <p className="news-feed-entry-title">New FAQ & Tutorial Pages</p>
                <p className="news-feed-entry-date">2020.12.17</p>
                <p className="news-feed-entry-content">
                    Check out the new DQO <Link to="/FAQ">FAQ</Link> and <Link to="/tutorials">tutorials</Link> pages! These resources are designed to help you get better acquainted with the ARM data quality process, as well as the various resources and tools we have made available for data monitoring. Is there is anything you would like to see added, please email us at <a href="mailto:dqmgr@arm.gov">dqmgr@arm.gov</a>. 
                </p>
              </div>
            </Col>
          </Row> 
          <Row className="news-feed-entry">
            <Col lg={6} md={12}>
              <div className="news-feed-entry-image-container">
                <div className="news-feed-entry-image">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/TNfHY-Vp9IM?start=280" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="news-feed-entry-text-container">
                <p className="news-feed-entry-title">National Weather Festival 2020 DQO Virtual Tour</p>
                <p className="news-feed-entry-date">2020.11.18</p>
                <p className="news-feed-entry-content">
                    Due to the annual <a href="https://www.ou.edu/nwc/nwf">National Weather festival</a> going virtual this year, Alyssa put together a brief video segment showcasing the DQO as part of a larger CIMMS virtual tour. This video is targeted towards a broad/younger audience but does a great job of explaining the sorts of things we do here at the DQO!
                </p>
              </div>
            </Col>
          </Row> 
          <Row className="news-feed-entry">
            <Col lg={6} md={12}>
              <div className="news-feed-entry-image-container">
                  <img className="news-feed-entry-image" src={dashboard_img}/>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="news-feed-entry-text-container">
                <p className="news-feed-entry-title">DQ Dashboard Tool Now Live</p>
                <p className="news-feed-entry-date">2019.07.18</p>
                <p className="news-feed-entry-content">
                     The new dq-dashboard web tool is now live on production! This tool will be the primary point of interaction for DQO Analysts. DQA progress, instrument assignments, and other helpful information is displayed in this application. <br/><br/>
                     Current analysts - please begin using this application as the starting point for your work.
                </p>
              </div>
            </Col>
          </Row> 
        </div>
      </div>
    )
  }
}

export class HomePage extends Component {
  render() {
    return (
        <Container style={{paddingBottom: 75}}>
          <Row>
            <Col lg={8} md={12}>
              <NewsFeed/>
            </Col>
            <Col lg={4} md={12}>
              <SideBar/>
            </Col>
          </Row>
        </Container>
    )
  }
}


class SideBar extends Component {
  render() {
    return (
      <div className="sidebar">
        <div className="about-blurb">
          <p className="about-blurb-title">Who We Are</p>
          <p className="about-blurb-text">
            The ARM Data Quality Office (DQO) is located at the University of Oklahoma within the Cooperative Institute for Severe and High-Impact Weather Research and Operations (CIWRO).
            <br/><br/> The DQO serves as the first line of defense for discovering ARM data quality issues via manual & automated data quality reporting alongside web-based tools for data inspection.
            <br/><br/><Link to="About">Read More</Link></p>
        </div>


        <div className="contacts">
          <p className="contacts-title">Contacts</p>
            <div className="contact-container">
                <a href="mailto:rpeppler@ou.edu">
                    <div className="contact-entry">
                        <p className="contact-name">Randy Peppler</p>
                        <p className="contact-role">Manager</p>
                    </div>
                </a>
                <a href="mailto:kkehoe@ou.edu">
                    <div className="contact-entry">
                        <p className="contact-name">Ken Kehoe</p>
                        <p className="contact-role">Assistant Manager</p>
                    </div>
                </a>
                {/*            <a href="mailto:atking@ou.edu">
              <div className="contact-entry">
                <p className="contact-name">Austin King</p>
                <p className="contact-role">Web Tools</p>
              </div>
            </a>*/}
                <a href="mailto:cgodine@ou.edu">
                    <div className="contact-entry">
                        <p className="contact-name">Corey Godine</p>
                        <p className="contact-role">Backend Software</p>
                    </div>
                </a>
                <a href="mailto:ajsockol@ou.edu">
                    <div className="contact-entry">
                        <p className="contact-name">Alyssa Sockol</p>
                        <p className="contact-role">Science Goals</p>
                    </div>
                </a>
                <a href="mailto:lishan.li-1@ou.edu">
                    <div className="contact-entry">
                        <p className="contact-name">Mia Li</p>
                        <p className="contact-role">Machine Learning Engineer</p>
                    </div>
                </a>
            </div>
        </div>
          {/*<div style={{overflow: "hidden"}} className="sidebar-pic">*/}
          {/*    <img className="sidebar-pic-img" src={team_img}/>*/}
          {/*</div>*/}
          {/*        <div className="quick-links">
          <p className="quick-links-title">Quick Links:</p>
          <p className="quick-links-link"><Link to="/tutorials">DQO Tutorials</Link></p>
          <p className="quick-links-link"><a href="https://dqwiki.arm.gov/dqcms/bin/view/DQdocs/">DQ Wiki Page</a></p>
          <p className="quick-links-link"><a href="https://www.arm.gov">ARM Homepage</a></p>
        </div>*/}
        
      </div>
    )
  }
}
